<template>
  <div>
    <div>
      <div
        class="modal"
        data-backdrop="static"
        id="nstp-student-information-modal"
      >
        <div class="modal-dialog rounded" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex justify-content-between mb-3">
                <div class="my-auto">
                  <div>
                    <h5 class="font-weight-bold mb-1">
                      {{ student.last_name }}, {{ student.first_name }}
                      {{ student.middle_name }}
                      {{ student.extension_name }}
                    </h5>
                    <span class="" v-if="showSerialNumber"
                      >{{ student.serial_number }} |
                      {{ student.issued_at | completeDateTime }}</span
                    >
                    <p class="">{{ student.abbrev }}</p>
                  </div>
                </div>
                <i class="bi bi-person-circle" style="font-size: 50px"></i>
              </div>

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="StudentInformationTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('StudentInformationTab')"
                  >
                    <i class="bi bi-person-vcard me-2"></i>Student Information
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="ProgressLogTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('ProgressLogTab')"
                  >
                    <i class="bi bi-graph-up-arrow me-2"></i>Progress Log
                  </button>
                </li>
              </ul>

              <div class="">
                <component
                  v-bind:is="currentTab"
                  :student="student"
                  :components="components"
                  :awardYears="awardYears"
                  :heiPrograms="heiPrograms"
                  @getApplications="getApplications"
                ></component>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressLogTab from './ProgressLogTab.vue';
import StudentInformationTab from './StudentInformationTab.vue';

export default {
  name: 'StudentInformationModal',
  components: {
    ProgressLogTab,
    StudentInformationTab,
  },
  props: {
    showSerialNumber: {
      required: true,
      type: Boolean,
    },
    components: {
      required: true,
      type: Array,
    },

    heiPrograms: {
      required: true,
      type: Array,
    },
    awardYears: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentTab: 'StudentInformationTab',
      student: new Form({
        id: '',
        abbrev: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        extension_name: '',
        hei_name: '',
        component: '',
        award_year: '',
        sex: '',
        birthdate: '',
        program_name: '',
        program_id: '',
        email: '',
        contact_no: '',
        street_or_brgy: '',
        town_or_city: '',
        province: '',
        created_at: '',
        serial_number: '',
        issued_by: '',
        issued_at: '',
        issued_by: '',
        status: '',
      }),
    };
  },
  methods: {
    open(student) {
      this.tabActive('StudentInformationTab');
      var modal = new bootstrap.Modal(
        document.getElementById('nstp-student-information-modal')
      );
      modal.toggle();
      this.student.fill(student);
    },

    getApplications() {
      this.$emit('getApplications');
    },

    tabActive(element) {
      const el = document.getElementById(this.currentTab);
      el.classList.remove('active');
      const selectedTab = document.getElementById(element);
      selectedTab.classList.add('active');
      this.currentTab = element;
    },
  },
};
</script>
