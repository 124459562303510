<template>
  <div>
    <div class="mt-3 mx-2">
      <div class="mb-3" v-if="editMode">
        <label for="" class="form-label">Name</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="student.last_name"
            placeholder="Last Name"
            :disabled="!editMode"
          />
          <input
            type="text"
            class="form-control"
            v-model="student.first_name"
            placeholder="First Name"
            :disabled="!editMode"
          />
          <input
            type="text"
            class="form-control"
            v-model="student.middle_name"
            placeholder="Middle Name"
            :disabled="!editMode"
          />
          <input
            type="text"
            class="form-control"
            v-model="student.extension_name"
            placeholder="Ext."
            :disabled="!editMode"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="" class="form-label">Component</label>
            <select
              class="form-select"
              v-model="student.component"
              :disabled="!editMode"
            >
              <option v-for="component in components" :key="component">
                {{ component }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="" class="form-label">Award Year</label>
            <select
              class="form-select"
              v-model="student.award_year"
              :disabled="!editMode"
            >
              <option v-for="(awardYear, index) in awardYears" :key="index">
                {{ awardYear }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Program</label>
        <select
          name=""
          class="form-select"
          id=""
          v-model="student.program_id"
          required
          :disabled="!editMode"
        >
          <option
            v-for="heiProgram in heiPrograms"
            :key="heiProgram.id"
            :value="heiProgram.program_id"
          >
            {{ heiProgram.program_name }}
          </option>
        </select>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="" class="form-label">Birthdate</label>
            <input
              type="date"
              class="form-control"
              v-model="student.birthdate"
              :disabled="!editMode"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="" class="form-label">Sex</label>
            <select
              name=""
              id=""
              class="form-select"
              v-model="student.sex"
              :disabled="!editMode"
            >
              <option value="MALE">MALE</option>
              <option value="FEMALE">FEMALE</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              v-model="student.email"
              :disabled="!editMode"
            />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="" class="form-label">Contact No</label>
            <input
              type="text"
              class="form-control"
              v-model="student.contact_no"
              :disabled="!editMode"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Street/Brgy.</label>
        <input
          type="text"
          class="form-control"
          v-model="student.street_or_brgy"
          :disabled="!editMode"
        />
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Town/City</label>
        <input
          type="text"
          class="form-control"
          v-model="student.town_or_city"
          :disabled="!editMode"
        />
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Province</label>
        <select
          name=""
          class="form-select"
          v-model="student.province"
          id=""
          :disabled="!editMode"
        >
          <option value="AGUSAN DEL NORTE">AGUSAN DEL NORTE</option>
          <option value="AGUSAN DEL SUR">AGUSAN DEL SUR</option>
          <option value="SURIGAO DEL NORTE">SURIGAO DEL NORTE</option>
          <option value="SURIGAO DEL SUR">SURIGAO DEL SUR</option>
          <option value="DINAGAT ISLANDS">DINAGAT ISLANDS</option>
        </select>
      </div>

      <div class="my-4" v-if="student.status == 'PENDING'">
        <button
          type="submit"
          class="btn"
          :class="{
            'btn-outline-secondary text-dark': !editMode,
            'btn-success': editMode,
          }"
          @click.prevent="saveOrEdit"
          :disabled="updating"
        >
          <i class="bi bi-pencil-square mr-1"></i>
          {{ editMode ? 'Save changes' : 'Edit' }}
        </button>

        <span
          class="spinner-border spinner-border-sm mx-3"
          v-if="updating"
        ></span>
      </div>

      <small class="text-muted"
        >Date Encoded: {{ student.created_at | completeDateTime }}</small
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentInformationTab',
  props: {
    student: {
      type: Object,
    },
    components: {
      required: true,
      type: Array,
    },

    heiPrograms: {
      required: true,
      type: Array,
    },
    awardYears: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      updating: false,
      editMode: false,
    };
  },
  methods: {
    saveOrEdit() {
      this.editMode ? this.saveChanges() : (this.editMode = !this.editMode);
    },
    saveChanges() {
      this.updating = !this.updating;
      axios
        .put('api/nstp/' + this.student.id, this.student)
        .then((res) => {
          this.updating = !this.updating;
          this.$emit('getApplications');
          this.toggleEditing();
        })
        .catch((error) => {
          this.updating = !this.updating;
          this.toggleEditing();
        });
    },

    toggleEditing() {
      this.editMode = !this.editMode;
    },
  },
};
</script>
