<template>
  <div id="print" v-show="isTableVisible">
    <header class="text-center">
      <div class="d-flex justify-content-center">
        <div>
          <img
            src="@/assets/images/ched-logo-nstp.png"
            width="100"
            length="100"
            alt="CHED Header Logo"
          />
        </div>
        <div>
          <img :src="hei.logo" width="100" length="100" alt="HEI Header Logo" />
        </div>
      </div>
      <h5>National Service Training Program</h5>
      <h5 class="mb-1">{{ hei.name }}</h5>
      <p>{{ hei.street }}, {{ hei.city }}, {{ hei.province }}</p>
    </header>

    <h5 class="text-center mt-5">LIST OF STUDENTS</h5>

    <table class="table table-sm mt-4" style="font-size: 12px">
      <caption class="text-center">
        * * * NOTHING FOLLOWS * * *
      </caption>
      <thead>
        <tr>
          <th>#</th>
          <th>NAME</th>
          <th>COMP.</th>
          <th>A.Y.</th>
          <th width="40%">PROGRAM</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in selectedStudents" :key="student.id">
          <td>{{ index + 1 }}</td>

          <td>
            <p class="mb-0">
              {{ student.last_name }}, {{ student.first_name }}
              {{ student.middle_name }} {{ student.extension_name }}
            </p>
            <span v-show="student.serial_number" class="form-text">
              {{ student.serial_number }}</span
            >
          </td>
          <td>{{ student.component }}</td>
          <td>{{ student.award_year }}</td>
          <td class="text-uppercase">{{ student.program_name }}</td>
          <td>{{ student.status }}</td>
        </tr>
      </tbody>
    </table>
    <footer>
      <p class="text-muted mb-0 small">CHED NSTP Portal</p>
      <small class="text-muted">Printed on {{ new Date() }}</small>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PrintStudentList',
  props: {
    selectedStudents: {
      required: true,
      type: Array,
      default: [],
    },
    isTableVisible: {
      required: true,
      type: Boolean,
    },
    hei: {
      required: true,
      type: Object,
      default: {},
    },
  },
  methods: {
    async print() {
      await this.$htmlToPaper('print');
    },
  },
};
</script>
