var provinces = [
  {
    CODE: '012800000',
    NAME: 'ILOCOS NORTE',
    REGIONCODE: '010000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0102800000',
  },
  {
    CODE: '012900000',
    NAME: 'ILOCOS SUR',
    REGIONCODE: '010000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0102900000',
  },
  {
    CODE: '013300000',
    NAME: 'LA UNION',
    REGIONCODE: '010000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0103300000',
  },
  {
    CODE: '015500000',
    NAME: 'PANGASINAN',
    REGIONCODE: '010000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0105500000',
  },
  {
    CODE: '020900000',
    NAME: 'BATANES',
    REGIONCODE: '020000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0200900000',
  },
  {
    CODE: '021500000',
    NAME: 'CAGAYAN',
    REGIONCODE: '020000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0201500000',
  },
  {
    CODE: '023100000',
    NAME: 'ISABELA',
    REGIONCODE: '020000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0203100000',
  },
  {
    CODE: '025000000',
    NAME: 'NUEVA VIZCAYA',
    REGIONCODE: '020000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0205000000',
  },
  {
    CODE: '025700000',
    NAME: 'QUIRINO',
    REGIONCODE: '020000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0205700000',
  },
  {
    CODE: '030800000',
    NAME: 'BATAAN',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0300800000',
  },
  {
    CODE: '031400000',
    NAME: 'BULACAN',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0301400000',
  },
  {
    CODE: '034900000',
    NAME: 'NUEVA ECIJA',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0304900000',
  },
  {
    CODE: '035400000',
    NAME: 'PAMPANGA',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0305400000',
  },
  {
    CODE: '036900000',
    NAME: 'TARLAC',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0306900000',
  },
  {
    CODE: '037100000',
    NAME: 'ZAMBALES',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0307100000',
  },
  {
    CODE: '037700000',
    NAME: 'AURORA',
    REGIONCODE: '030000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0307700000',
  },
  {
    CODE: '041000000',
    NAME: 'BATANGAS',
    REGIONCODE: '040000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0401000000',
  },
  {
    CODE: '042100000',
    NAME: 'CAVITE',
    REGIONCODE: '040000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0402100000',
  },
  {
    CODE: '043400000',
    NAME: 'LAGUNA',
    REGIONCODE: '040000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0403400000',
  },
  {
    CODE: '045600000',
    NAME: 'QUEZON',
    REGIONCODE: '040000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0405600000',
  },
  {
    CODE: '045800000',
    NAME: 'RIZAL',
    REGIONCODE: '040000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0405800000',
  },
  {
    CODE: '174000000',
    NAME: 'MARINDUQUE',
    REGIONCODE: '170000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1704000000',
  },
  {
    CODE: '175100000',
    NAME: 'OCCIDENTAL MINDORO',
    REGIONCODE: '170000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1705100000',
  },
  {
    CODE: '175200000',
    NAME: 'ORIENTAL MINDORO',
    REGIONCODE: '170000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1705200000',
  },
  {
    CODE: '175300000',
    NAME: 'PALAWAN',
    REGIONCODE: '170000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1705300000',
  },
  {
    CODE: '175900000',
    NAME: 'ROMBLON',
    REGIONCODE: '170000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1705900000',
  },
  {
    CODE: '050500000',
    NAME: 'ALBAY',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0500500000',
  },
  {
    CODE: '051600000',
    NAME: 'CAMARINES NORTE',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0501600000',
  },
  {
    CODE: '051700000',
    NAME: 'CAMARINES SUR',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0501700000',
  },
  {
    CODE: '052000000',
    NAME: 'CATANDUANES',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0502000000',
  },
  {
    CODE: '054100000',
    NAME: 'MASBATE',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0504100000',
  },
  {
    CODE: '056200000',
    NAME: 'SORSOGON',
    REGIONCODE: '050000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '0506200000',
  },
  {
    CODE: '060400000',
    NAME: 'AKLAN',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0600400000',
  },
  {
    CODE: '060600000',
    NAME: 'ANTIQUE',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0600600000',
  },
  {
    CODE: '061900000',
    NAME: 'CAPIZ',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0601900000',
  },
  {
    CODE: '063000000',
    NAME: 'ILOILO',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0603000000',
  },
  {
    CODE: '064500000',
    NAME: 'NEGROS OCCIDENTAL',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0604500000',
  },
  {
    CODE: '067900000',
    NAME: 'GUIMARAS',
    REGIONCODE: '060000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0607900000',
  },
  {
    CODE: '071200000',
    NAME: 'BOHOL',
    REGIONCODE: '070000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0701200000',
  },
  {
    CODE: '072200000',
    NAME: 'CEBU',
    REGIONCODE: '070000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0702200000',
  },
  {
    CODE: '074600000',
    NAME: 'NEGROS ORIENTAL',
    REGIONCODE: '070000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0704600000',
  },
  {
    CODE: '076100000',
    NAME: 'SIQUIJOR',
    REGIONCODE: '070000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0706100000',
  },
  {
    CODE: '082600000',
    NAME: 'EASTERN SAMAR',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0802600000',
  },
  {
    CODE: '083700000',
    NAME: 'LEYTE',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0803700000',
  },
  {
    CODE: '084800000',
    NAME: 'NORTHERN SAMAR',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0804800000',
  },
  {
    CODE: '086000000',
    NAME: 'SAMAR',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0806000000',
  },
  {
    CODE: '086400000',
    NAME: 'SOUTHERN LEYTE',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0806400000',
  },
  {
    CODE: '087800000',
    NAME: 'BILIRAN',
    REGIONCODE: '080000000',
    ISLANDGROUPCODE: 'VISAYAS',
    PSGC10DIGITCODE: '0807800000',
  },
  {
    CODE: '097200000',
    NAME: 'ZAMBOANGA DEL NORTE',
    REGIONCODE: '090000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '0907200000',
  },
  {
    CODE: '097300000',
    NAME: 'ZAMBOANGA DEL SUR',
    REGIONCODE: '090000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '0907300000',
  },
  {
    CODE: '098300000',
    NAME: 'ZAMBOANGA SIBUGAY',
    REGIONCODE: '090000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '0908300000',
  },
  {
    CODE: '101300000',
    NAME: 'BUKIDNON',
    REGIONCODE: '100000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1001300000',
  },
  {
    CODE: '101800000',
    NAME: 'CAMIGUIN',
    REGIONCODE: '100000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1001800000',
  },
  {
    CODE: '103500000',
    NAME: 'LANAO DEL NORTE',
    REGIONCODE: '100000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1003500000',
  },
  {
    CODE: '104200000',
    NAME: 'MISAMIS OCCIDENTAL',
    REGIONCODE: '100000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1004200000',
  },
  {
    CODE: '104300000',
    NAME: 'MISAMIS ORIENTAL',
    REGIONCODE: '100000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1004300000',
  },
  {
    CODE: '112300000',
    NAME: 'DAVAO DEL NORTE',
    REGIONCODE: '110000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1102300000',
  },
  {
    CODE: '112400000',
    NAME: 'DAVAO DEL SUR',
    REGIONCODE: '110000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1102400000',
  },
  {
    CODE: '112500000',
    NAME: 'DAVAO ORIENTAL',
    REGIONCODE: '110000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1102500000',
  },
  {
    CODE: '118200000',
    NAME: 'DAVAO DE ORO',
    REGIONCODE: '110000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1108200000',
  },
  {
    CODE: '118600000',
    NAME: 'DAVAO OCCIDENTAL',
    REGIONCODE: '110000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1108600000',
  },
  {
    CODE: '124700000',
    NAME: 'COTABATO',
    REGIONCODE: '120000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1204700000',
  },
  {
    CODE: '126300000',
    NAME: 'SOUTH COTABATO',
    REGIONCODE: '120000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1206300000',
  },
  {
    CODE: '126500000',
    NAME: 'SULTAN KUDARAT',
    REGIONCODE: '120000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1206500000',
  },
  {
    CODE: '128000000',
    NAME: 'SARANGANI',
    REGIONCODE: '120000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1208000000',
  },
  {
    CODE: '140100000',
    NAME: 'ABRA',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1400100000',
  },
  {
    CODE: '141100000',
    NAME: 'BENGUET',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1401100000',
  },
  {
    CODE: '142700000',
    NAME: 'IFUGAO',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1402700000',
  },
  {
    CODE: '143200000',
    NAME: 'KALINGA',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1403200000',
  },
  {
    CODE: '144400000',
    NAME: 'MOUNTAIN PROVINCE',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1404400000',
  },
  {
    CODE: '148100000',
    NAME: 'APAYAO',
    REGIONCODE: '140000000',
    ISLANDGROUPCODE: 'LUZON',
    PSGC10DIGITCODE: '1408100000',
  },
  {
    CODE: '160200000',
    NAME: 'AGUSAN DEL NORTE',
    REGIONCODE: '160000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1600200000',
  },
  {
    CODE: '160300000',
    NAME: 'AGUSAN DEL SUR',
    REGIONCODE: '160000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1600300000',
  },
  {
    CODE: '166700000',
    NAME: 'SURIGAO DEL NORTE',
    REGIONCODE: '160000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1606700000',
  },
  {
    CODE: '166800000',
    NAME: 'SURIGAO DEL SUR',
    REGIONCODE: '160000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1606800000',
  },
  {
    CODE: '168500000',
    NAME: 'DINAGAT ISLANDS',
    REGIONCODE: '160000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1608500000',
  },
  {
    CODE: '150700000',
    NAME: 'BASILAN',
    REGIONCODE: '150000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1900700000',
  },
  {
    CODE: '153600000',
    NAME: 'LANAO DEL SUR',
    REGIONCODE: '150000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1903600000',
  },
  {
    CODE: '153800000',
    NAME: 'MAGUINDANAO',
    REGIONCODE: '150000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1903800000',
  },
  {
    CODE: '156600000',
    NAME: 'SULU',
    REGIONCODE: '150000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1906600000',
  },
  {
    CODE: '157000000',
    NAME: 'TAWI-TAWI',
    REGIONCODE: '150000000',
    ISLANDGROUPCODE: 'MINDANAO',
    PSGC10DIGITCODE: '1907000000',
  },
];

export const sorted_provinces = provinces.sort((a, b) => {
  const nameA = a.NAME.toUpperCase();
  const nameB = b.NAME.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
});
