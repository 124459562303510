<template>
  <div>
    <form @submit.prevent="submit">
      <div class="card shadow-sm" style="border-radius: 0.5rem !important">
        <div class="card-body">
          <div class="row">
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="form-label"
                  ><strong class="text-danger">* </strong>Component</label
                >
                <select
                  class="form-select"
                  v-model="selectedComponent"
                  required
                >
                  <option disabled selected hidden value="">Choose</option>
                  <option v-for="component in components" :key="component">
                    {{ component }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="form-label"
                  ><strong class="text-danger">* </strong>Academic Year</label
                >
                <select
                  class="form-select"
                  v-model="selectedAwardYear"
                  required
                >
                  <option disabled selected hidden value="">Choose</option>
                  <option v-for="awardYear in awardYears" :key="awardYear">
                    {{ awardYear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="mb-3">
                <label for="" class="form-label"
                  ><strong class="text-danger">* </strong>Program</label
                >
                <select
                  v-model="selectedProgramId"
                  class="form-select"
                  id=""
                  required
                >
                  <option value="" selected hidden disabled>Choose</option>
                  <option
                    v-for="heiProgram in heiPrograms"
                    :key="heiProgram.hei_program_id"
                    :value="heiProgram.program_id"
                  >
                    {{ heiProgram.program_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mt-4" style="border-radius: 0.5rem !important">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h5 class="my-auto">Student List</h5>
            <div>
              <div class="btn-group">
                <button
                  class="btn btn-outline-secondary text-dark"
                  @click.prevent="showAddStudentModal"
                >
                  <i class="bi bi-person-plus me-2"></i> Add student
                </button>
                <button
                  class="btn btn-outline-secondary text-dark"
                  @click.prevent="showImportCsvModal"
                >
                  <i class="bi bi-filetype-csv me-2"></i> Import via CSV
                </button>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <table id="students-table" class="table table-bordered table-sm">
              <caption>
                <i class="bi bi-info-circle-fill me-2"></i
                >We recommend encoding students in batches to avoid crashing
                your browser.
              </caption>
              <thead>
                <tr>
                  <th>#</th>
                  <th>NAME</th>
                  <th>SEX</th>
                  <th>BIRTHDATE</th>
                  <th>EMAIL</th>
                  <th>CONTACT NO</th>
                  <th>ADDRESS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="!students.length" class="text-center" colspan="7">
                    List is empty. Try adding students.
                  </td>
                </tr>
                <tr v-for="(student, index) in students" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ student.last_name }}, {{ student.first_name }}
                    {{ student.middle_name }} {{ student.extension_name }}
                  </td>

                  <td>
                    {{ student.sex }}
                  </td>
                  <td>
                    {{ student.birthdate }}
                  </td>

                  <td>
                    {{ student.email }}
                  </td>
                  <td>
                    {{ student.contact_no }}
                  </td>
                  <td>
                    {{
                      `${student.street_or_brgy}, ${student.town_or_city}, ${student.province}`
                    }}
                  </td>
                  <td class="align-middle">
                    <a
                      href="#"
                      title="Delete row"
                      @click.prevent="removeSpecificTableRow(index)"
                      ><i class="bi bi-trash text-danger"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              v-show="validationErrors.length"
              class="alert alert-danger"
              role="alert"
            >
              <p class="fw-bold mb-0">
                Oops! We have found some validation errors.
              </p>
              <p>Please double check your entries.</p>

              <ul class="">
                <li v-for="error in validationErrors" :key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <button
          id="nstp-application-submit-btn"
          class="btn btn-primary"
          type="submit"
          :disabled="!students.length"
        >
          Submit
        </button>
        <button class="btn btn-link" @click.prevent="resetForm">
          Reset form
        </button>
      </div>

      <div class="mt-3" v-show="showSuccessMessage">
        <p class="text-success">
          <i class="bi bi-check-circle-fill me-2"></i> You have successfully
          encoded the students.
        </p>
      </div>

      <div class="modal" data-backdrop="static" id="nstp-student-info-modal">
        <div class="modal-dialog rounded" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Student Information</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="alert alert-warning">
                <i class="bi bi-exclamation-triangle-fill me-2"></i> Please
                ensure that all required fields are completed and that the
                information entered is accurate.
              </div>

              <table id="add-student-form-table" class="table table-borderless">
                <tbody>
                  <tr>
                    <td width="30%">
                      <span class="text-danger">* </span>Last Name
                    </td>
                    <td>
                      <input
                        type="text"
                        name="Last Name"
                        title="Last Name"
                        class="form-control text-uppercase"
                        v-model="form.last_name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>First Name</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.first_name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Middle Name</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.middle_name"
                        placeholder="Leave blank if none"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Ext. Name</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.extension_name"
                        placeholder="Leave blank if none"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Sex</td>
                    <td>
                      <select
                        name=""
                        class="form-select"
                        id=""
                        v-model="form.sex"
                      >
                        <option value="" selected hidden disabled>
                          Choose
                        </option>
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Birthdate</td>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form.birthdate"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Email</td>
                    <td>
                      <input
                        type="email"
                        class="form-control"
                        v-model="form.email"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Contact No</td>
                    <td>
                      <input
                        type="text"
                        maxlength="11"
                        minlength="11"
                        class="form-control"
                        v-model="form.contact_no"
                        placeholder="11-digit mobile number"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td><span class="text-danger">* </span>Street/Brgy</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.street_or_brgy"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Town/City</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.town_or_city"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td><span class="text-danger">* </span>Province</td>
                    <td>
                      <select
                        name=""
                        class="form-select"
                        v-model="form.province"
                        id=""
                      >
                        <option value="" selected hidden disabled>
                          Choose
                        </option>
                        <option
                          v-for="province in provinces"
                          :value="province.NAME"
                          :key="province.CODE"
                        >
                          {{ province.NAME }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-show="showRequiredFieldsAlert" class="alert alert-danger">
                <i class="bi bi-exclamation-diamond mr-1"></i> Please make sure
                to fill in all required fields before submitting the form. Thank
                you!
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="addStudentToList(form)"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="modal" data-backdrop="static" id="import-csv-modal">
      <div class="modal-dialog rounded" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="alert alert-warning">
              <i class="bi bi-exclamation-triangle-fill me-2"></i
              ><strong> REMINDERS FOR THE IMPORT PROCESS </strong>
              <ol class="ps-3 mt-2">
                <li>
                  Make sure to follow the prescribed template provided. This is
                  to prevent any errors during the file parsing.
                  <a
                    href="https://drive.google.com/file/d/1NYR88bOVI7caP7nA7W1Zl3SvwhqGaE0d/view?usp=share_link"
                    target="_blank"
                    >Link to the template</a
                  >
                </li>
                <li>Do not make any alterations to the template.</li>
                <li>Make sure to use the correct file format. (.csv)</li>
                <li>
                  To avoid the crashing of your browser, it is advisable to
                  import and submit students in batches.
                </li>
                <li>
                  For student names that contain special characters such as Ñ or
                  ñ, make sure that the encoding of your CSV file is set to
                  <strong>CSV UTF-8</strong>.
                </li>
              </ol>
            </div>

            <div class="mb-3">
              <label for="" class="form-label">CSV File</label>
              <input
                id="import-csv-file-input"
                type="file"
                class="form-control"
                accept=".csv"
                @change="getImportedCsvFile"
              />
            </div>

            <div class="text-danger mt-4" v-show="parseErrors.length">
              <p class="fw-bold mb-0">
                <i class="bi bi-exclamation-triangle-fill me-2"></i> Parse Error
              </p>
              <span
                >There was a problem parsing your file. Please resolve the
                error(s) below to proceed.</span
              >
              <ul class="ps-3 mt-2">
                <li v-for="(error, index) in parseErrors" :key="index">
                  {{ error.message }} [Row: {{ error.row }}]
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-bs-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="importToStudentList"
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasEmptyOrNullProperties } from '../../../common/helpers';
import Papa from 'papaparse';
import { sorted_provinces } from '../../../common/provinces';

export default {
  name: 'Apply',
  props: {
    components: {
      required: true,
      type: Array,
    },
    awardYears: {
      required: true,
      type: Array,
    },
    heiPrograms: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      checkbox: '',
      provinces: sorted_provinces,
      showRequiredFieldsAlert: false,
      showSuccessMessage: false,
      selectedComponent: '',
      selectedAwardYear: '',
      selectedProgramId: '',
      validationErrors: [],
      students: [],
      parseErrors: [],
      csvData: [],
      csv: '',
      importCsvModal: '',
      modal: {},
      form: {
        last_name: '',
        first_name: '',
        extension_name: '',
        middle_name: '',
        birthdate: '',
        sex: '',
        street_or_brgy: '',
        town_or_city: '',
        province: '',
        email: '',
        contact_no: '',
      },
    };
  },
  methods: {
    showAddStudentModal() {
      this.modal = new bootstrap.Modal(
        document.getElementById('nstp-student-info-modal')
      );
      this.modal.show();
    },

    showImportCsvModal() {
      this.importCsvModal = new bootstrap.Modal(
        document.getElementById('import-csv-modal')
      );
      this.importCsvModal.show();
      document.getElementById('import-csv-file-input').value = '';
    },

    getImportedCsvFile(event) {
      this.csv = event.target.files[0];
      this.parseCsv();
    },

    parseCsv() {
      Papa.parse(this.csv, {
        complete: (results) => {
          console.log(results);
          if (results.errors.length) {
            this.parseErrors = results.errors;
          }
          this.csvData = results.data;
        },
        error: function (err, file, inputElem, reason) {
          alert(reason);
        },
        skipEmptyLines: 'greedy',
        header: true,
      });
    },

    importToStudentList() {
      this.students = [];
      this.students.push(...this.csvData);
      this.importCsvModal.hide();
    },

    hideAddStudentModal() {
      this.modal.hide();
    },

    addStudentToList(form) {
      if (hasEmptyOrNullProperties(form)) {
        this.showRequiredFieldsAlert = true;
        return;
      }
      let student = Object.assign({}, form);
      this.students.push(student);
      this.hideAddStudentModal();
      this.emptyAddStudentTableForm();
      this.showRequiredFieldsAlert = false;
    },

    removeSpecificTableRow(index) {
      this.students.splice(index, 1);
    },

    resetForm() {
      this.students = [];
      this.selectedAwardYear =
        this.selectedComponent =
        this.selectedProgramId =
          '';
    },

    emptyAddStudentTableForm() {
      for (let key in this.form) {
        this.form[key] = '';
      }
    },

    validate(student, index) {
      if (hasEmptyOrNullProperties(student)) {
        this.validationErrors.push(
          'Row: ' +
            index +
            '- Please make sure to fill in all required fields before submitting the application.'
        );
      }
    },

    submit() {
      if (
        !confirm(
          'ATTENTION: Make sure to double check all entries before submitting. \nYou can no longer update this records once submitted.  \n\nAre you sure you want to proceed?'
        )
      ) {
        return;
      }

      this.validationErrors = [];
      this.students.forEach((student, index) => this.validate(student, index));

      if (!this.validationErrors.length) {
        let btn = document.getElementById('nstp-application-submit-btn');
        btn.disabled = true;
        btn.innerHTML = `<span class="spinner-border spinner-border-sm me-1"></span> Submitting...`;

        axios
          .post('api/nstp', {
            students: this.students,
            award_year: this.selectedAwardYear,
            component: this.selectedComponent,
            program_id: this.selectedProgramId,
          })
          .then((res) => {
            btn.disabled = false;
            btn.innerHTML = 'Submit';
            this.showSuccessMessage = true;
            this.resetForm();
          })
          .catch((err) => {
            btn.disabled = false;
            btn.innerHTML = 'Submit';
            this.showSuccessMessage = false;
            this.validationErrors.push(err.response.data.message);
          });
      }
    },
  },

  computed: {},
};
</script>

<style scoped>
#add-student-form-table input[type='text'],
input[type='email'] {
  text-transform: uppercase;
}

#students-table tbody {
  text-transform: uppercase;
}

#add-student-form-table td {
  vertical-align: middle !important;
}

@media (max-width: 1200px) {
  .table-responsive {
    width: 100%;
    overflow-x: scroll;
  }
}
</style>
