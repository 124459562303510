<template>
  <div>
    <div>
      <div class="modal" id="print-loader-modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="">
                <div v-if="loader" class="d-flex align-items-center">
                  <strong>Preparing certificate...</strong>
                  <div
                    class="spinner-border spinner-border-sm ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
                <div v-else>
                  <div class="mb-3">
                    <label for="" class="form-label">Date of Certificate</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="certificateDate"
                    />
                  </div>

                  <div class="form-check mb-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      v-model="showBorder"
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Do not show black border
                    </label>
                  </div>

                  <button
                    class="btn btn-primary"
                    @click="print"
                    :disabled="!certificateDate"
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="certificate" v-show="false">
      <div
        style="font-family: Georgia, serif"
        class="px-2 py-2"
        :style="{ border: showBorder ? '' : '1px solid black' }"
      >
        <div id="header" class="text-center">
          <div class="row">
            <div class="col">
              <img
                src="@/assets/images/ched-logo-nstp.png"
                length="150"
                width="150"
                alt="CHED Header Logo"
              />
            </div>
            <div class="col-7">
              <p class="mb-0 fs-6">REPUBLIC OF THE PHILIPPINES</p>
              <p class="mb-0 fs-5">Office of the President</p>
              <p class="mb-0 fs-5">COMMISSION ON HIGHER EDUCATION</p>
              <h4>NATIONAL SERVICE TRAINING PROGRAM</h4>
            </div>
            <div class="col">
              <img :src="src" length="150" width="150" alt="HEI Logo" />
            </div>
          </div>
        </div>

        <div id="hei-name" class="text-center">
          <h4 class="fw-bold mb-0">{{ hei.name }}</h4>
          <p class="fs-5">{{ hei.city }}, {{ hei.province }}</p>
        </div>

        <div id="sub-header" class="text-center mt-4">
          <h1 class="fw-bold">CERTIFICATE OF COMPLETION</h1>
          <p class="fst-italic">is awarded to</p>
          <h1>
            {{ student.first_name }}
            {{ student.middle_name }} {{ student.last_name }}
            {{ student.extension_name }}
          </h1>
        </div>

        <div id="cert-body-text" class="text-center mx-5 px-3 mt-3">
          <p class="fst-italic fs-5">
            for having successfully completed the National Service Training
            Program (NSTP) under the component of
            <strong
              >{{ components[student.component] }} ({{
                student.component
              }})</strong
            >
            for Academic Year {{ student.award_year }} with
            <strong>Serial Number {{ student.serial_number }}</strong
            >.
          </p>
        </div>

        <div id="footer" class="text-center mx-5 px-3">
          <p class="fst-italic fs-5">
            Given this {{ certificateDate | standardDate }} at {{ hei.name }},
            {{ hei.city }}, {{ hei.province }}.
          </p>
        </div>

        <div id="seal" class="mt-1">
          <p style="font-size: 8px">NOT VALID WITHOUT SEAL</p>
        </div>

        <div id="signatories" class="mt-5" style="font-size: 15px">
          <div class="row text-center">
            <div class="col mx-0 px-0">
              <p style="font-size: 16.5px" class="fw-bold mb-0">
                {{ $store.getters.getUserFullName }}
              </p>
              <span>NSTP Director/Coordinator</span>
            </div>

            <div class="col mx-0 px-0">
              <p style="font-size: 16.5px" class="fw-bold mb-0">
                {{ registrarFullName }}
              </p>
              <span>Registrar</span>
            </div>

            <div class="col mx-0 px-0">
              <p style="font-size: 16.5px" class="fw-bold mb-0">
                {{ hei.president }}
              </p>
              <span>{{ hei.head_title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Certificate',
  data() {
    return {
      student: new Form({
        first_name: '',
        last_name: '',
        middle_name: '',
        extension_name: '',
        component: '',
        award_year: '',
        serial_number: '',
      }),
      hei: {},
      modal: {},
      loader: false,
      certificateDate: '',
      registrarFullName: '',
      showBorder: false,
      src: '',
      components: {
        CWTS: 'Civic Welfare Training Service',
        LTS: 'Literacy Training Service',
      },
    };
  },
  methods: {
    async open(student) {
      this.modal = new bootstrap.Modal(
        document.getElementById('print-loader-modal'),
        {
          backdrop: 'static',
          keyboard: false,
        }
      );
      this.modal.show();
      this.student.fill(student);
      this.getHei();
    },

    getHei() {
      this.loader = true;

      axios
        .get('api/heis/' + this.$store.state.user.hei_code)
        .then((res) => {
          this.hei = res.data;
          this.src = this.hei.logo;
          this.getRegistrarName();
        })
        .catch((err) => {
          this.loader = false;
        });
    },

    getRegistrarName() {
      axios
        .get('api/nstp/registrar-name/' + this.$store.state.user.hei_code)
        .then((res) => {
          this.registrarFullName = res.data;
          this.loader = false;
        });
    },

    async print() {
      await this.$htmlToPaper('certificate', {
        styles: [
          './custom.css',
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
        ],
      });
      this.modal.hide();
    },
  },
};
</script>
