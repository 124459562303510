<template>
  <div class="">
    <nav class="navbar navbar-light bg-white fixed-top shadow-sm py-0">
      <a class="navbar-brand">
        <img
          src="@/assets/images/side-bar-logo-black.png"
          alt=""
          class=""
          width="200"
          length="200"
        />
        NATIONAL SERVICE TRAINING PROGRAM PORTAL
      </a>
      <span class="navbar-text">
        <img :src="hei.logo" alt="" class="mx-1" width="50" length="50" />
        <strong>{{ $store.getters.getUserFullName }} | {{ hei.abbrev }}</strong>
        <button
          class="btn btn-link text-dark mx-3"
          @click.prevent="handleLogout"
        >
          Logout
        </button>
      </span>
    </nav>
    <br />
    <br />
    <div class="container mt-5 px-5">
      <div class="card shadow-sm mt-4" style="border-radius: 0.5rem !important">
        <div class="card-body">
          <ul class="nav nav-tabs" id="nstp-client-tabs">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="EnrollmentList"
                @click.prevent="tabActive('EnrollmentList')"
                href="#"
                ><i class="bi bi-card-list me-2"></i>Masterlist</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                id="EncodeStudent"
                @click.prevent="tabActive('EncodeStudent')"
                href="#"
                ><i class="bi bi-person-add me-2"></i>Encode Students</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                id="Applications"
                @click.prevent="tabActive('Applications')"
                href="#"
                ><i class="bi bi-hourglass-split me-2"></i>Requests for Serial
                Number</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="my-4">
        <component
          :components="components"
          :heiPrograms="heiPrograms"
          :awardYears="awardYears"
          :statuses="statuses"
          :hei="hei"
          v-bind:is="currentTab"
        >
        </component>
      </div>

      <p class="pb-4">
        If you have any questions or feedback, please don't hesitate to reach
        out to our support team using this
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSca-eAQZlzJV8HUkJDHe4ESVZIdvjuWAXgT7aME7WdS5lXIGw/viewform"
          target="_blank"
          >link</a
        >
        or refer to the
        <a
          href="https://drive.google.com/file/d/1Iw2ztgvggtpjr8hyVvbLRA22Mc1SD7Ke/view?usp=share_link"
          target="_blank"
          >user manual</a
        >. Thank you.
      </p>
    </div>

    <div class="modal" data-bs-backdrop="static" id="feedback-form-modal">
      <div class="modal-dialog modal-dialog-centered rounded" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container">
              <div class="text-center mb-3">
                <img
                  src="@/assets/images/feedback-form-header-image.png"
                  alt="Feedback Form Header Image"
                  class="mb-3"
                />
                <h3>Help us improve!</h3>
              </div>
              <p class="mb-3">
                Hi there! We kindly request a few moments of your time to share
                your experience with us by rating our services.
              </p>

              <div class="text-center mt-4">
                <a
                  @click="startTimer"
                  style="font-size: 18px"
                  class="font-weight-bold btn btn-primary"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf54ZCey-Ua-I2h5mgg2hNLXx715LhiWMNonoAZZzvbjTRXQQ/viewform"
                  target="_blank"
                  >Client Satisfaction Measurement</a
                >
                <p class="form-text">(Takes only 1-2 minutes!)</p>
              </div>

              <p>
                The <strong>Close</strong> button will activate after filling up
                the form. Thank you.
              </p>
            </div>
          </div>

          <div class="modal-footer">
            <button
              :disabled="!timerEnabled"
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              @click="updateFeedbackSubmission"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EncodeStudent from './components/EncodeStudent.vue';
import Applications from './components/Applications.vue';
import EnrollmentList from './components/EnrollmentList.vue';
import { awardYears, statuses } from '../../common/award-years.js';

export default {
  name: 'NstpClient',
  components: {
    EncodeStudent,
    Applications,
    EnrollmentList,
  },
  data() {
    return {
      currentTab: 'EnrollmentList',
      components: ['CWTS', 'LTS'],
      heiPrograms: [],
      awardYears: awardYears,
      statuses: statuses,
      hei: {},
      timerEnabled: false,
      countdown: 5,
    };
  },
  methods: {
    tabActive(element) {
      const el = document.getElementById(this.currentTab);
      el.classList.remove('active');
      const selectedTab = document.getElementById(element);
      selectedTab.classList.add('active');
      this.currentTab = element;
    },

    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$store.state.user?.hei_code)
        .then((response) => {
          this.heiPrograms = response.data;
        })
        .catch((error) => {});
    },

    authCheck() {
      axios
        .get('api/check-auth')
        .then((response) => {
          if (!response.data) {
            this.$store.commit('setAuthentication', false);
            this.$store.commit('setUser', null);
            this.$router.push({ name: 'Login' });
          }
        })
        .catch((error) => {});
    },
    getHeiInfo() {
      axios
        .get('api/heis/' + this.$store.state.user.hei_code)
        .then((response) => {
          this.hei = response.data;
        })
        .catch((error) => {});
    },
    hasFeedbackSubmitted() {
      axios
        .get('api/nstp/feedback/check-submission/' + this.$store.state.user.id)
        .then((res) => res.data || this.promptFeedbackFormModal())
        .catch();
    },
    promptFeedbackFormModal() {
      var modal = new bootstrap.Modal(
        document.getElementById('feedback-form-modal')
      );
      modal.toggle();
    },

    updateFeedbackSubmission() {
      axios
        .put('api/nstp/feedback/update-submission/' + this.$store.state.user.id)
        .then()
        .catch();
    },

    handleLogout() {
      axios.post('/logout').then((response) => {
        this.$store.commit('logout', false);
        this.$router.push({ name: 'Login' });
      });
    },

    startTimer() {
      this.timerEnabled = false; // Disable the button during countdown
      let timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.timerEnabled = true; // Enable the button after countdown
        }
      }, 1000);
    },
  },
  mounted() {
    this.authCheck();
    this.getHeiPrograms();
    this.getHeiInfo();
    this.hasFeedbackSubmitted();
  },
};
</script>
