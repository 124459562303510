<template>
  <div>
    <div class="card shadow-sm" style="border-radius: 0.5rem !important">
      <div class="card-body">
        <div class="">
          <p class="fw-bold">
            To search and filter students, simply use the fields below.
          </p>
          <form
            @submit.prevent="
              search(DEFAULT_URL, {
                tableLoader: { enabled: false },
                searchLoader: { enabled: true },
              })
            "
          >
            <div class="row">
              <div class="col-auto">
                <div class="mb-3">
                  <label for="" class="form-label">Last Name</label>
                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search student"
                    v-model="params.last_name"
                  />
                </div>
              </div>

              <div class="col-auto">
                <div class="mb-3">
                  <label for="" class="form-label">Component</label>
                  <select
                    class="form-select col-auto"
                    v-model="params.component"
                  >
                    <option selected value="">All</option>
                    <option v-for="component in components" :key="component">
                      {{ component }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-auto">
                <div class="mb-3">
                  <label for="" class="form-label">Academic Year</label>
                  <select
                    class="form-select col-auto"
                    v-model="params.award_year"
                  >
                    <option selected value="">All</option>
                    <option v-for="awardYear in awardYears" :key="awardYear">
                      {{ awardYear }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-auto">
                <div class="mb-3">
                  <label for="" class="form-label">Status</label>
                  <select class="form-select col-auto" v-model="params.status">
                    <option selected value="">All</option>
                    <option v-for="(status, index) in statuses" :key="index">
                      {{ status }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-auto">
                <div class="mb-3">
                  <label for="" class="form-label">Program</label>
                  <select
                    class="form-select col-auto"
                    v-model="params.program_id"
                  >
                    <option selected value="">All</option>
                    <option
                      v-for="heiProgram in heiPrograms"
                      :key="heiProgram.id"
                      :value="heiProgram.program_id"
                    >
                      {{ heiProgram.program_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="">
              <button
                type="submit"
                class="btn btn-primary"
                style="background-color: #0d6efd; color: #fff"
                :disabled="searchLoader"
              >
                <span
                  v-if="searchLoader"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span v-else>Search or filter</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card shadow-sm mt-4" style="border-radius: 0.5rem !important">
      <div class="card-body">
        <div class="alert alert-danger" v-show="showErrorMessage">
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          {{ errorMessage }}
        </div>
        <div class="d-flex justify-content-between mt-2">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-auto">
                  <div class="mb-3">
                    <label for="" class="col-form-label">No. of rows</label>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <select
                      name=""
                      id=""
                      :disabled="!students.length"
                      class="form-select form-select ml-2"
                      v-model="params.per_page"
                      @change="
                        search(DEFAULT_URL, {
                          searchLoader: { enabled: false },
                          tableLoader: { enabled: false },
                        })
                      "
                    >
                      <option value="10">10</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="dropdown">
                    <button
                      class="btn btn-outline-secondary text-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      :disabled="!selectedStudents.length"
                      data-bs-auto-close="true"
                    >
                      <i class="bi bi-list-ul me-2"></i>Actions
                      <span class="mx-1" v-show="selectedStudents.length"
                        >({{ selectedStudents.length }})</span
                      >
                      <div
                        v-if="actionLoader"
                        class="spinner-border spinner-border-sm mx-1"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li v-if="params.status == ''" class="dropdown-item">
                        <i class="bi bi-info-circle-fill me-2"></i>To enable
                        options, make sure to
                        <p class="mb-0">
                          filter students based on their status.
                        </p>
                      </li>
                      <li v-if="params.status == ''">
                        <hr class="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          :class="{ disabled: actions['ONGOING-NSTP1'] }"
                          href="#"
                          @click.prevent="
                            updateStatus('ONGOING-NSTP1', {
                              actionLoader: { enabled: true },
                            })
                          "
                          ><i class="bi bi-person-up me-2"></i> Update status to
                          ongoing (NSTP 1)</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          :class="{ disabled: actions['ONGOING-NSTP2'] }"
                          href="#"
                          @click.prevent="
                            updateStatus('ONGOING-NSTP2', {
                              actionLoader: { enabled: true },
                            })
                          "
                          ><i class="bi bi-person-up me-2"></i> Update status to
                          ongoing (NSTP 2)</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          :class="{ disabled: actions['COMPLETED'] }"
                          href="#"
                          @click.prevent="
                            updateStatus('COMPLETED', {
                              actionLoader: { enabled: true },
                            })
                          "
                          ><i class="bi bi-clipboard-check me-2"></i> Mark as
                          completed</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          :class="{ disabled: actions['REQUESTED FOR SERIAL'] }"
                          href="#"
                          @click.prevent="
                            updateStatus('REQUESTED FOR SERIAL', {
                              actionLoader: { enabled: true },
                            })
                          "
                          ><i class="bi bi-award me-2"></i> Request for serial
                          number</a
                        >
                      </li>
                      <li><hr class="dropdown-divider" /></li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="printSelectedStudents"
                          ><i class="bi bi-printer me-2"></i> Print selected
                          students</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          :class="{
                            disabled: actions['DELETE'],
                            'text-danger': !actions['DELETE'],
                          }"
                          href="#"
                          @click.prevent="deleteStudents"
                          ><i class="bi bi-trash me-2"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-right">
            <div class="mx-2 mt-1">
              <label for="" class="col-form-label">{{
                `${pagination.from || 0}-${pagination.to || 0} of ${
                  pagination.total || 0
                }`
              }}</label>
            </div>
            <nav>
              <ul class="pagination mb-0">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.prev_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only"></span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.next_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="table-responsive">
          <table
            id="hei-nstp-masterlist"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="checked"
                    @change="checked ? selectAll() : unselectAll()"
                  />
                </th>
                <th>ID</th>
                <th>NAME</th>
                <th>COMP.</th>
                <th>A.Y.</th>
                <th width="35%">PROGRAM</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!students.length && !tableLoader">
                <td colspan="7">
                  Empty list. Try using the search or filter function.
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="7">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="student in students"
                :key="student.id"
                @click="openStudentInformationModal(student)"
              >
                <td>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="selectedStudents"
                    :value="student"
                    @click.stop
                  />
                </td>
                <td>{{ student.id }}</td>
                <td>
                  {{ student.last_name }}, {{ student.first_name }}
                  {{ student.middle_name }} {{ student.extension_name }}
                  <div
                    v-if="student.serial_number"
                    class="form-text mt-0 fw-bold"
                  >
                    {{ student.serial_number }}
                    <a
                      href="#"
                      @click.stop.prevent="showPrintCertificateModal(student)"
                      class="mx-1"
                      ><i class="bi bi-printer-fill text-success"></i
                    ></a>
                  </div>
                </td>
                <td>{{ student.component }}</td>
                <td>{{ student.award_year }}</td>
                <td class="text-uppercase">{{ student.program_name }}</td>
                <td>
                  <span
                    class="badge"
                    :class="[
                      student.status !== 'WITH SERIAL'
                        ? 'bg-secondary'
                        : 'bg-success',
                    ]"
                    >{{ student.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <StudentInformationModal
      ref="StudentInformationModal"
      :showSerialNumber="false"
      :components="components"
      :heiPrograms="heiPrograms"
      :awardYears="awardYears"
      :hei="hei"
      @getApplications="
        search(DEFAULT_URL, {
          tableLoader: { enabled: false },
          searchLoader: { enabled: false },
        })
      "
    ></StudentInformationModal>

    <PrintStudentList
      ref="PrintStudentList"
      :selectedStudents="selectedStudents"
      :isTableVisible="false"
      :hei="hei"
    ></PrintStudentList>

    <Certificate ref="Certificate"></Certificate>
  </div>
</template>

<script>
import StudentInformationModal from './StudentInformationModal.vue';
import PrintStudentList from './PrintStudentList.vue';
import Certificate from './Certificate.vue';

export default {
  name: 'EnrollmentList',
  props: {
    components: {
      required: true,
      type: Array,
    },
    awardYears: {
      required: true,
      type: Array,
    },
    heiPrograms: {
      required: true,
      type: Array,
    },
    statuses: {
      required: true,
      type: Array,
    },
    hei: {
      required: true,
      type: Object,
    },
  },
  components: {
    StudentInformationModal,
    PrintStudentList,
    Certificate,
  },
  data() {
    return {
      errorMessage:
        'You can only delete students who have a PENDING status. Please double check your selection.',
      showErrorMessage: false,
      checked: false,

      students: [],
      selectedStudents: [],
      actionLoader: false,
      tableLoader: false,
      searchLoader: false,
      pagination: {},
      input: '',
      DEFAULT_URL: 'api/nstp/filter-students',
      awardYear: '',
      component: '',
      status: '',
      heiProgram: '',
      params: {
        last_name: '',
        per_page: 10,
        award_year: '',
        component: '',
        status: '',
        program_id: '',
      },
      actions: {
        PENDING: true,
        'ONGOING-NSTP1': true,
        'ONGOING-NSTP2': true,
        COMPLETED: true,
        'REQUESTED FOR SERIAL': true,
        DELETE: true,
      },
    };
  },
  methods: {
    openStudentInformationModal(student) {
      this.$refs.StudentInformationModal.open(student);
    },

    showPrintCertificateModal(student) {
      this.$refs.Certificate.open(student);
    },

    updateStatus(selectedStatus, loader) {
      this.actionLoader = loader.actionLoader.enabled;

      axios
        .put(
          'api/nstp/update-status/selected-students/' + selectedStatus,
          this.selectedStudents
        )
        .then((response) => {
          this.actionLoader = false;
          this.checked = false;
          this.search(this.DEFAULT_URL, {
            tableLoader: { enabled: false },
            searchLoader: { enabled: false },
          });
          this.selectedStudents = [];
        })
        .catch((error) => {
          alert('Something went wrong.');
          this.actionLoader = false;
        });
    },

    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    search(page_url, loader) {
      this.tableLoader = loader.tableLoader.enabled;
      this.searchLoader = loader.searchLoader.enabled;

      axios
        .post(page_url, this.params)
        .then((res) => {
          this.tableLoader = false;
          this.searchLoader = false;
          this.makePagination(res.data);
          this.students = res.data.data;
          this.selectedStudents = [];
          this.checked = false;
          this.renderAllowableActions();
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
        });
    },
    selectAll() {
      this.selectedStudents = [];
      this.students.forEach((student) => {
        this.selectedStudents.push(student);
      });
    },

    unselectAll() {
      this.selectedStudents = [];
    },

    printSelectedStudents() {
      this.$refs.PrintStudentList.print();
    },

    deleteStudents() {
      if (!this.isEligibleForDelete(this.selectedStudents)) {
        this.showErrorMessage = true;
        return;
      }

      if (
        !confirm(
          'WARNING: You are about to delete a student record. This action cannot be undone. Are you sure you want to proceed?'
        )
      ) {
        return;
      }

      this.actionLoader = true;

      axios
        .post('api/nstp/delete/selected-students', this.selectedStudents)
        .then((response) => {
          this.showErrorMessage = false;
          this.checked = false;
          this.actionLoader = false;
          this.search(this.DEFAULT_URL, {
            tableLoader: { enabled: false },
            searchLoader: { enabled: false },
          });
        })
        .catch((error) => {
          this.showErrorMessage = true;
          this.actionLoader = false;
        });
    },

    isEligibleForDelete(students) {
      return students.every((student) => student.status == 'PENDING');
    },

    resetAllowableActions() {
      for (const prop in this.actions) {
        this.actions[prop] = true;
      }
    },

    renderAllowableActions() {
      this.resetAllowableActions();

      if (this.params.status == 'PENDING') {
        this.actions['ONGOING-NSTP1'] = this.actions['DELETE'] = false;
      }

      if (this.params.status == 'ONGOING-NSTP1') {
        this.actions['ONGOING-NSTP2'] = false;
      }

      if (this.params.status == 'ONGOING-NSTP2') {
        this.actions['COMPLETED'] = false;
      }

      if (this.params.status == 'COMPLETED') {
        this.actions['REQUESTED FOR SERIAL'] = false;
      }
    },
  },
  mounted() {
    this.search(this.DEFAULT_URL, {
      tableLoader: { enabled: true },
      searchLoader: { enabled: false },
    });
  },
};
</script>

<style scoped>
#hei-nstp-masterlist tr {
  cursor: pointer !important;
}
</style>
